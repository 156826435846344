

















import { Button, Cell, CellGroup } from "vant";
import Vue from "vue";
import Component from "vue-class-component";

@Component({
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button
  }
})
export default class BatteryPlanOrderExtraPayList extends Vue {
  planOrderId = 0;
  list = [];

  created() {
    this.planOrderId = Number(this.$route.params.planOrderId);
    this.getData();
  }

  getData() {
    this.$axios.post("/api/manage/getBatteryPlanOrderExtraPayList", { planOrderId: this.planOrderId }).then(res => {
      const data = res.data.data;
      this.list = data.list;
    });
  }
  toExtraPaySave(extraPayId: number) {
    this.$router.push("/battery-plan-order/extra-pay/save/" + extraPayId + "?planOrderId=" + this.planOrderId);
  }
}
